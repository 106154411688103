import React from 'react';

function Lines() {
  return (
    <div className="lines">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Lines;
